<template>
    <section>
        <tabla-general
        alto="calc(100vh - 247px)"
        class="mt-4"
        :data="filtroData"
        :mostrar-buscador="false"
        :usar-paginacion="false"
        style="width: 100%"
        header-row-class-name="text-general f-15"

        @seleccion="verDelivery"
        >
            <el-table-column prop="nombre" label="Delivery" class-name=" cr-pointer text-center text-general" width="180" sortable />
            <el-table-column prop="name" label="Cedis" width="180" class-name=" cr-pointer text-center text-general" sortable>
                <template slot-scope="{ row }">
                    <div class="row mx-0 align-items-center">
                        <p class="text-general" v-text="_.get(row.cedi,'nombre','Sin cedis')" />
                        <div v-if="row.cedisTotal > 0" class="bg-general3 text-white br-20 px-3 mx-2">
                            +{{ row.cedisTotal }}
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="pedidos" label="Entregas últ. semana." class-name=" cr-pointer text-general text-center" sortable />
            <el-table-column prop="total" class-name=" cr-pointer text-general text-center" label="Valor Entregas últ. semana" sortable />
            <el-table-column prop="antiguedad" label="Antiguedad (meses)" class-name=" cr-pointer text-center text-general" sortable />
            <el-table-column label="Estado" class-name=" cr-pointer text-center text-general" sortable>
                <template slot-scope="scope">
                    <div class="row mx-0 align-items-center">
                        <div :class="`${scope.row.estado == 1 ? 'bg-green' : 'bg-general2'} br-50 mx-1`" style="width:12px;height:12px;" />
                        <p class="text-general" v-text="scope.row.estado == 1 ? 'Activo' : 'inactivo'" />
                    </div>
                </template>
            </el-table-column>
        </tabla-general>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Delivery from "~/services/delivery/index";

export default {
    props: {
        buscar: {
            type: String,
            default: ''
        },
    },
    data(){
        return {
            tableData: []
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        filtroData(){
            return this.tableData.filter(el =>
                !this.buscar
                || el.nombre.toLowerCase().includes(this.buscar.toLowerCase())
            )
        }
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        filtroData(val){
            this.$store.commit('delivery/setCantidad', val.length)
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.getTabla()
                    }, 1000);
                }else{
                    await this.getTabla()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        async getTabla(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Delivery.getTabla(params)
                data.map(el => {
                    el.pedidos = this.agregarSeparadoresNumero(el.pedidos)
                    el.total = this.separadorNumero(el.total)
                })
                this.tableData = data
            } catch (error){
                this.error_catch(error)
            }
        },
        verDelivery(row){
            console.log(row);
            this.$router.push({name: 'admin.delivery.ver', params: { id_delivery: row.id }})
        }
    }
}
</script>

<style lang="css" scoped>
.bg-green{
    background-color: #28D07B;
}
</style>
