<template>
    <section>
        <div class="row mx-0">
            <div v-for="(data,a) in filtroActivos" :key="a" class="col-auto my-2">
                <div class="card-delivery border-gris bg-white br-12" :class="$can('card_delivery_ver')? 'cr-pointer' : ''" @click="verDelivery(data)">
                    <img :src="data.imagen" class="w-100 obj-cover br-t-12" height="150" />
                    <p class=" f-16 text-general nombre f-600 px-2 my-2 text-center f-15">
                        {{ data.nombre }}
                    </p>
                    <p class="text-general2 my-2 text-center f-14">
                        {{ _.get(data.cedi,'nombre','Sin cedis') }}
                    </p>
                    <div class="d-middle mx-3">
                        <p class="text-general2 text-center f-14 f-600">
                            <i class="icon-star f-18 text-warning" />
                            <span> {{ agregarSeparadoresNumero(data.calificacion,1) }} </span>
                        </p>
                        <p class="text-general2 text-center f-14 f-600 ml-auto">
                            <i class="icon-moped f-18" />
                            <span> {{ data.pedidos }} </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="inactivos.length > 6" class="row mx-0 my-2">
            <div class="col-auto ml-auto text-general f-500 d-middle">
                Hay {{ inactivos.length - 6 }} Deliverys ocultos por inactividad
            </div>
            <div class="col-auto">
                <div class="bg-general3 text-white br-20 px-3 py-1 shadow cr-pointer" @click="verMas">
                    Ver más
                </div>
            </div>
        </div>
        <div v-else class="row mx-0 my-2" />
        <div class="row mx-0">
            <div class="col-12 mt-4 mb-3">
                <p class="text-general f-18 f-500">
                    Deliverys inactivos
                </p>
            </div>
            <div v-for="(data,i) in filtroInactivosLimite" :key="i" class="col-auto my-2">
                <div class="card-delivery border-gris bg-white br-12" :class="$can('card_delivery_ver')? 'cr-pointer' : ''" @click="verDelivery(data)">
                    <img :src="data.imagen" class="w-100 obj-cover br-t-12" height="150" />
                    <p class=" f-16 text-general nombre f-600 my-2 text-center f-15">
                        {{ data.nombre }}
                    </p>
                    <p class="text-general2 my-2 text-center f-14">
                        {{ _.get(data.cedi,'nombre','Sin cedis') }}
                    </p>
                    <p class="text-general2 text-center f-14 f-600">
                        <i class="icon-moped f-18" />
                        <span> {{ data.pedidos }} </span>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Delivery from "~/services/delivery/index";

export default {
    props: {
        buscar: {
            type: String,
            default: ''
        },
    },
    data(){
        return {
            activos: [],
            inactivos: [],
            inactivosLimite: [],
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        filtroActivos(){
            return this.activos.filter(el =>
                !this.buscar
                || el.nombre.toLowerCase().includes(this.buscar.toLowerCase())
            )
        },
        filtroInactivosLimite(){
            return this.inactivosLimite.filter(el =>
                !this.buscar
                || el.nombre.toLowerCase().includes(this.buscar.toLowerCase())
            )
        },
        deliverys(){
            return this.filtroActivos.length + this.filtroInactivosLimite.length
        }
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        deliverys(val){
            this.$store.commit('delivery/setCantidad', val)
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.getMosaico()
                    }, 1000);
                }else{
                    await this.getMosaico()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        async getMosaico(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Delivery.getMosaico(params)
                data.activos.map(el => {
                    el.pedidos = this.agregarSeparadoresNumero(el.pedidos)
                })
                data.inactivos.map(el => {
                    el.pedidos = this.agregarSeparadoresNumero(el.pedidos)
                })
                this.activos = data.activos
                this.inactivos = data.inactivos
                this.inactivosLimite = data.inactivos.slice(0,6)
            } catch (error){
                this.error_catch(error)
            }
        },
        verDelivery(row){
            if(this.$can('card_delivery_ver')){
                this.$router.push({name: 'admin.delivery.ver', params: { id_delivery: row.id }})
            }
        },
        verMas(){
            this.inactivosLimite = this.inactivos
        }
    }
}
</script>

<style lang="scss" scoped>
.card-delivery{
    width: 162px;
    min-height: 250px;
    border-radius: 12px;
}
</style>
