<template>
    <div>
        <div class="row mx-0 align-items-center">
            <router-link :to="{name: 'admin.delivery'}">
                <div class="btn-back f-14">
                    <i class="icon-left" />
                </div>
            </router-link>
            <img :src="delivery.imagen" width="36" height="36" class="obj-cover br-5 border-gris ml-3" />
            <div class="col-auto px-2 f-18 text-general f-500">
                {{ delivery.nombre }}
            </div>
        </div>
        <tabs :tabs="rutas" class="border-bottom mb-2" />
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 180px);">
            <router-view />
        </div>
    </div>
</template>

<script>
import Delivery from "~/services/delivery/index";
export default {
    data(){
        return {
            rutas: [
                {
                    titulo: 'Informacíon del delivery',
                    ruta: 'admin.delivery.ver.informacion',
                    can: 'card_delivery_ver'
                },
                {
                    titulo: 'Entregas programadas',
                    ruta: 'admin.delivery.ver.programadas',
                    can: 'tab_delivery_entregas_programadas'
                },
                {
                    titulo: 'Historial de entregas',
                    ruta: 'admin.delivery.ver.historial',
                    can: 'tab_delivery_historial_entregas'
                },
                {
                    titulo: 'Ubicación',
                    ruta: 'admin.delivery.ver.ubicacion',
                    can: 'tab_delivery_historial_entregas'
                }
            ],
            delivery: {
                nombre: '',
                imagen: '/img/no-imagen/sin_cliente.svg',
            }
        }
    },
    computed: {
        idDelivery(){
            return this.$route.params.id_delivery
        }
    },
    mounted(){
        this.getHeaderDelivery()
    },
    methods: {
        async getHeaderDelivery(){
            try {

                const { data } = await Delivery.getHeaderDelivery(this.idDelivery)

                this.delivery = data.delivery

            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>
