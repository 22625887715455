import axios from 'axios'
const API = "delivery"

const delivery = {
    getTabla: (params) => axios(`${API}/tabla`, { params }),
    getMosaico: (params) => axios(`${API}/mosaico`, { params }),
    getDelivery: (idDelivery,params) => axios(`${API}/delivery/${idDelivery}`, { params }),
    getHeaderDelivery: (idDelivery) => axios(`${API}/header-delivery/${idDelivery}`),
    crearDelivery: (data) => axios.post(`${API}/delivery`, data),
    cambiarEstado: (data) => axios.post(`${API}/cambiar-estado`, data),
    editarDelivery: (idDelivery, data) => axios.put(`${API}/${idDelivery}/delivery`, data),
    updateFinalizarPedido: (idDelivery) => axios.put(`${API}/${idDelivery}/update-finalizar-pedido`),
    updateComprar: (idDelivery) => axios.put(`${API}/${idDelivery}/update-comprar`),

    //Apis para entregas delivery
    listarDeliverys(fecha){
        return axios(`${API}/entregas/get-deliverys?fecha=${fecha}`)
    },
    getDataDelivery(idDelivery, fecha){
        return axios(`${API}/entregas/${idDelivery}/get-data?fecha=${fecha}`)
    },
    getDataEntrega(idEntrega){
        return axios(`${API}/entregas/${idEntrega}/detalle-entrega`)
    },
    getGraficaEntregaVsDinero(idDelivery){
        return axios(`${API}/entregas/${idDelivery}/chart-entregas-vs-dinero`)
    },
    getGraficaEntregaVsPesosAndDistancias(idDelivery){
        return axios(`${API}/entregas/${idDelivery}/chart-entregas-vs-volumenes`)
    },
    getTablaComparativa(params){
        return axios(`${API}/entregas/comparativa`,{params})
    },
}

export default delivery
