<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom" />
        <div class="custom-scroll overflow-auto br-t-12" style="height:calc(100vh - 132px);">
            <router-view />
        </div>
    </section>
</template>

<script>

export default {
    data(){
        return {
            rutas: [
                {
                    titulo: 'Lista de deliverys',
                    ruta: 'admin.delivery.lista'
                },
                {
                    titulo: 'Lista de pedidos',
                    ruta: 'admin.delivery.pedidos'
                },
                {
                    titulo: 'Comparativa de entregas',
                    ruta: 'admin.delivery.comparativa-entregas'
                }
            ]
        }
    }
}
</script>
